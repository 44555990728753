import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageTitle from '../components/PageTitle';
import WhatsAppBubble from '../components/WhatsAppBubble';
import Loader from '../components/Loader';
import NotFound404 from '../components/NotFound404';
import ServerError500 from '../components/ServerError500';
import CookieBanner from '../components/CookieBanner';

const Home = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Home')), 1500);
    });
});

const About = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/About')), 1500);
    });
});

const Services = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Services')), 1500);
    });
});

const Contact = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Contact')), 1500);
    });
});

const Jobs = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Jobs')), 1500);
    });
});

const JobDetails = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/JobDetails')), 1500);
    });
});

const Candidate = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Candidate')), 1500);
    });
});

const Recruiter = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Recruiter')), 1500);
    });
});

const Terms = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Terms')), 1500);
    });
});

const Privacy = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Privacy')), 1500);
    });
});

const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/Login')), 1500);
    });
});

const HowToGetHired = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/HowToGetHired')), 1500);
    });
});

const HowToHire = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/HowToHire')), 1500);
    });
});

const InterviewTips = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/InterviewTips')), 1500);
    });
});

const CvWritingTips = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../views/CvWritingTips')), 1500);
    });
});

function MainNav() {
    return (
        <Router>
            <PageTitle />
            <Suspense fallback={<Loader />}>
                <CookieBanner/>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/services' element={<Services />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/jobs' element={<Jobs />} />
                    <Route path='/job-details/:id' element={<JobDetails />} />
                    <Route path='/candidate' element={<Candidate />} />
                    <Route path='/recruiter' element={<Recruiter />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/resources/how-to-get-hired' element={<HowToGetHired />} />
                    <Route path='/resources/how-to-hire' element={<HowToHire />} />
                    <Route path='/resources/interview-tips' element={<InterviewTips />} />
                    <Route path='/resources/cv-writing-tips' element={<CvWritingTips />} />
                    <Route path='/500' element={<ServerError500 />} />
                    <Route path='*' element={<NotFound404 />} />
                </Routes>
                <WhatsAppBubble />
            </Suspense>
        </Router>
    );
}

export default MainNav;
