import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        document.title = 'Accueil - Congo Digital Jobs';
        break;
      case '/about':
        document.title = 'À propos - Congo Digital Jobs';
        break;
      case '/services':
        document.title = 'Services - Congo Digital Jobs';
        break;
      case '/contact':
        document.title = 'Contact - Congo Digital Jobs';
        break;
      case '/jobs':
        document.title = 'Offres d\'emploi - Congo Digital Jobs';
        break;
      case '/candidate':
        document.title = 'Candidat - Congo Digital Jobs';
        break;
      case '/recruiter':
        document.title = 'Recruteur - Congo Digital Jobs';
        break;
      case '/terms':
        document.title = 'Conditions d\'utilisation - Congo Digital Jobs';
        break;
      case '/privacy':
        document.title = 'Politique de Confidentialité - Congo Digital Jobs';
        break;
      case '/login':
        document.title = 'Connexion - Congo Digital Jobs';
        break;
      case '/resources/how-to-get-hired':
        document.title = 'Comment se faire recruter - Congo Digital Jobs';
        break;
      case '/resources/how-to-hire':
        document.title = 'Comment recruter - Congo Digital Jobs';
        break;
      case '/resources/interview-tips':
        document.title = 'Conseils pour les entretiens - Congo Digital Jobs';
        break;
      case '/resources/cv-writing-tips':
        document.title = 'Conseils pour rédiger un CV - Congo Digital Jobs';
        break;
      case '/500':
        document.title = 'Erreur serveur - Congo Digital Jobs';
        break;
      default:
        document.title = 'Congo Digital Jobs';
    }
  }, [location.pathname]);

  return null;
};

export default PageTitle;
