import React from 'react';
import Logo from './../assets/images/logo/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Loader() {
    return (
        <div className="flex items-center justify-center h-screen bg-white">
            <div className="relative text-center">
                <img src={Logo} alt="Congo Digital Jobs" className="w-24 h-24 mx-auto mb-4 animate-pulse" />
                <h2 className="relative inline-block mb-2 text-2xl font-bold text-gray-800">
                    <span className='relative inline-block'>

                        Congo Digital Jobs
                    </span>
                        <FontAwesomeIcon
                            icon={faSearch}
                            className="absolute text-5xl text-gray-500 animate-search-horizontal"
                        />
                    {/* <span className="relative inline-block">
                    </span> */}
                </h2>
                <div className="flex justify-center mt-6">
                    <div className="w-4 h-4 mx-1 rounded-full bg-first-color animate-bounce"></div>
                    <div className="w-4 h-4 mx-1 delay-200 rounded-full bg-second-color animate-bounce"></div>
                    <div className="w-4 h-4 mx-1 rounded-full bg-first-color-2 animate-bounce delay-400"></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;
